.CalendarEvent {
    background: var(--whiteBaseColor);
    cursor: pointer;

    &:hover {
        & .Image {
            opacity: 0.8;
        }
    }

    & .Location,
    & .Time {
        @mixin p40;
        display: flex;
        align-items: center;

        & :global(.SvgIcon) {
            @mixin fixedRatioSvgIcon 16px;
            margin-right: 8px;
        }
    }
}

.Image {
    @mixin aspectRatio 9/16;
    @mixin imageHoverAnimation;
}

.Body {
    padding: 16px 16px 24px;

    @media (--tablet) {
        padding: 16px 24px 32px;
    }
}

.Heading {
    @mixin p30;
    margin: 0 0 16px;

    & .Link {
        @mixin hyphenateWords;
        display: block;
    }
}

.Location {
    @mixin hyphenateWords;
    margin-bottom: 8px;
}

.Header {
    border-bottom: 1px solid var(--blackLighten50Color);
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.Description {
    @mixin hyphenateWords;
    @mixin p30;
    margin: 0;
}
