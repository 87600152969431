@define-mixin daysListItemLayout $columnSpan {
    grid-column: span $columnSpan;

    & .Event {
        lost-column: 1 / $columnSpan;
    }
}

.CalendarBlock {
    background-color: var(--blackLighten60Color);
    padding: 32px 0 64px;

    @media (--tablet) {
        padding: 64px 0;
    }
}

.Inner {
    @mixin wrapper;
}

.Heading {
    @mixin h40;
}

.Date {
    margin-bottom: 8px;

    & .Day {
        @mixin h30;
        font-weight: 500;
        margin-right: 4px;
    }
}

.Events {
    @mixin clearList;
    margin-bottom: 32px;

    @media (--tablet) {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        margin-bottom: 8px;
    }

    & .Event {
        margin-bottom: 8px;

        @media (--tablet) {
            flex-grow: 1;
            margin-bottom: 16px;

            & > :only-child {
                height: 100%;
            }
        }
    }
}

.LinkContainer {
    display: flex;
    justify-content: flex-end;
}

.DaysList {
    @mixin clearList;

    @media (--tablet) {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        column-gap: var(--gutterWidth);

        & .DaysListItem {
            display: flex;
            flex-direction: column;

            &.DaysListItem--oneEvent {
                @mixin daysListItemLayout 1;
            }

            &.DaysListItem--twoEvents {
                @mixin daysListItemLayout 2;
            }

            &.DaysListItem--threeEvents {
                @mixin daysListItemLayout 3;
            }

            &.DaysListItem--fourEvents {
                @mixin daysListItemLayout 4;
            }
        }
    }
}
